#jobs {

    .section-3 {
        background-color: $black;

        .grid {
            padding: 100px 0;
        }

        .r-1 {
            color: white;
            margin-bottom: 70px;

            .c-2 {
                @include set-grid-prop(margin-left, 3, 2);
            }
        }

        .r-2 {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;

            [class^='column'] {
                float: none;
            }
        }

        .column-3 {
            color: white;
            @include set-grid-prop(margin-left, 2, 1);
            margin-top: 60px;

            &:first-child,
            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0;
            }

            .icon {
                width: 42px;
                height: 42px;
                border: 3px solid white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 1px;

                svg {
                    flex: 0 0 auto;
                    object-fit: contain;
                    width: 70%;
                    //height: 70%;
                }
            }

            .title {
                margin-top: 30px;
                @include druk-medium(18px, 24px);
            }

            .description {
                margin-top: 30px;
                font-size: 16px;
                line-height: 20px;
                padding-left: 14px;
                border-left: 1px solid white;
            }

            .button-line {
                margin-top: 30px;
            }
        }
    }

    .section-4 {
        padding: 100px 0;
        display: none;
    }

    @media screen and (max-width: $mobile) {
        .section-3 {

            .grid {
                padding: 60px 0;
            }

            .r-1 {

                .c-2 {
                    margin-top: 30px;
                }
            }

            .column-3 {
                margin-top: 50px !important;

                &:first-child {
                    margin-top: 0 !important;
                }
            }
        }
    }

}