.partial.block-certifications {
    border-top: 1px solid $black;

    .grid {
        padding: 100px 0;
    }

    .bg-img {
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 220px;
        transform: translate(-100%, 0);
        z-index: 1;
    }

    .r-2 {

        .h2-title {
            margin-bottom: 60px;
        }

        .column-6 {
            @include set-grid-prop(margin-left, 1, 0);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            &:first-child {
                margin-left: 0;
            }
        }

        .logo-item {
            width: 50%;
            padding-left: 10px;
            margin-bottom: 40px;

            a {
                display: block;
            }

            &:first-child {
                margin-left: 0;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .r-3 {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    @media screen and (max-width: $mobile) {
        .grid {
            padding: 60px 0;
        }

        .bg-img {
            display: none;
        }

        .bg-line {
            display: none;
        }

        .r-2 {
            flex-direction: column;

            .column-6 {
                margin-left: 0;
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                .logo-item {
                    padding-left: 0;
                    width: 100%;
                    align-content: center;
                    justify-content: center;
                    text-align: center;

                    img {
                        display: inline-block;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .r-3 {
            margin-top: 60px;
            justify-content: center;
        }
    }
}