#formation {

    .section-2 {
        border-bottom: 1px solid $black;

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^="column"] {
                float: none;
            }
        }

        .content {
            display: flex;
            align-items: center;
            padding: 60px 60px 60px 0;
        }

        .illustration {
            @include set-grid-prop(width, 5.5, 6);
            @include set-grid-prop(margin-left, 0.5, 0);
            background-color: $black;
            padding: 100px 0;
            padding-left: 20px;

            &:after {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                z-index: 1;
                width: 100vw;
                height: 100%;
                background-color: $black;
            }

            .img-container {
                z-index: 2;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .section-3 {
        padding: 100px 0;

        .r-1 {
            margin-bottom: 70px;

            .c-2 {
                @include set-grid-prop(margin-left, 3, 2);
            }
        }

        .r-2 {

        }
    }

    @media screen and (max-width: $mobile) {
        .section-2 {

            .grid {
                width: 100%;
            }

            .r-1 {
                flex-direction: column;

                .illustration {
                    order: 1;
                    padding: 0;

                    .img-container {
                        width: 100%;
                        height: auto;
                    }
                }

                .content {
                    order: 2;
                    padding: 60px 20px;
                }
            }
        }

        .section-3 {
            padding: 60px 0;
        }
    }

}