/**
HEADER
 */
#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
    z-index: 30;

    .header__top {
        background-color: black;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        #menu {

            ul {
                display: flex;
                align-items: center;

                li {
                    padding: 0 10px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        height: 50%;
                        width: 1px;
                        background-color: white;
                        top: calc(25% + 1px);
                        right: 0;
                        transform: skewX(-20deg);
                    }

                    &:last-child {

                        &:first-child {
                            padding-left: 0;
                        }

                        &:after {
                            display: none;
                            padding-right: 0;
                        }
                    }

                    a {

                        span {
                            display: inline-block;
                            @include din-medium(10px, 10px, 0.136em);
                            color: white;
                            text-transform: uppercase;
                            border-bottom: 1px solid transparent;
                            transition: border-color 0.3s $easeOutQuart;
                        }

                        html.desktop & {

                            &:hover {

                                span {
                                    border-color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header__bottom {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 0 40px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        background-color: white;

        .main-logo {
            flex: 0 1 auto;

            img {
                max-width: 100%;

                &.white {
                    display: none;
                }
            }
        }

        #menu {
            margin-left: auto;
            padding-left: 30px;

            ul {
                display: flex;
                align-items: center;

                li {
                    margin-left: 30px;

                    a {
                        @include druk-medium(13px, 13px);
                        display: inline-block;
                        transition: color 0.6s $easeOutQuart, border-color 0.6s $easeOutQuart, background-color 0.6s $easeOutQuart;
                        border-bottom: 1px solid transparent;
                        padding-bottom: 4px;
                    }

                    &.border {
                        a {
                            border: 1px solid var(--main-bg-color);
                            text-transform: uppercase;
                            padding: 14px 24px 12px 24px;
                        }
                    }

                    html.desktop & {
                        &:hover:not(.border) {
                            a {
                                color: var(--main-bg-color);
                                border-color: var(--main-bg-color);
                            }
                        }

                        &:hover.border {
                            a {
                                color: white;
                                background-color: var(--main-bg-color);
                            }
                        }
                    }

                    &.current-menu-item {
                        &:not(.border) {
                            a {
                                color: var(--main-bg-color);
                                border-color: var(--main-bg-color);
                            }
                        }

                        &.border {
                            a {
                                color: white;
                                background-color: var(--main-bg-color);
                            }
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    body.page-template-tpl-contact-agent &,
    body.page-template-tpl-agent &,
    body.page-template-tpl-jobs &,
    body.metier-template-default & {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .header__bottom {
            background-color: $black;
            color: white;

            .main-logo {

                img.color {
                    display: none;
                }

                img.white {
                    display: block;
                }
            }

            #menu {

                ul {

                    li.border {

                        a {
                            background-color: transparent;
                            color: var(--main-bg-color);
                        }
                    }
                }
            }
        }
    }
}

/**
HEADER MOBILE
 */
#header-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 30;
    background-color: white;

    &.shadow {
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    }

    .header__top {
        display: flex;
        align-items: center;

        .main-logo {

            img {
                &.white {
                    display: none;
                }
            }
        }

        .open {
            margin-left: auto;
            width: 30px;

            .line {
                position: relative;
                height: 2px;
                width: 30px;
                background-color: black;
                margin-top: 6px;

                &:first-child {
                    margin-top: 0;
                }

                &.l-2 {
                    width: 20px;
                }

                &.l-3 {
                    width: 10px;
                }
            }
        }
    }
}

/**
MENU MOBILE
 */
#menu-mobile {
    @include full-fixed;
    background-color: white;
    z-index: 100;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow-y: auto;
    @include autoAlpha(0);

    .menu-mobile__wrapper {
        padding-bottom: 50px;
    }

    .menu-mobile__top {
        display: flex;
        align-items: center;
        padding: 20px;

        .main-logo {

            img {
                &.white {
                    display: none;
                }
            }
        }

        .close {
            margin-left: auto;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .menu-mobile__bottom {

        .select-companies {
            margin-left: 0;
            height: 40px;

            .select-companies__title {
                padding: 0 20px;
                justify-content: flex-end;

                .text {
                    flex: 0 0 auto;
                }

                svg {
                    margin-left: 10px;
                }
            }
        }

        #menu.slave {
            margin-top: 8vh;
            padding: 0 20px;

            ul {

                li {

                    a {
                        @include din-medium(14px, 40px, 0.19em);
                        text-transform: uppercase;
                    }
                }
            }
        }

        #menu.main {
            padding: 0 20px;
            margin-top: 8vh;

            ul {

                li {

                    a {
                        @include druk-medium(16px, 40px);
                    }

                    &.border {
                        margin-top: 50px;

                        a {
                            border: 1px solid var(--main-bg-color);
                            text-transform: uppercase;
                            padding: 14px 24px 12px 24px;
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }
                }
            }
        }
    }
}

/**
PARTIALS
 */
.select-companies {
    height: 100%;
    margin-left: 30px;

    .select-companies__title {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: var(--main-bg-color);
        padding: 0 35px;
        cursor: pointer;

        .text {
            flex: 1 0 auto;
            @include druk-medium(12px, 12px);
            color: white;
        }

        svg {
            flex: 0 0 auto;
            fill: white;
            margin-left: 8px;
            width: 13px;
            height: 8px;
        }
    }

    .select-companies__list {
        position: absolute;
        right: 0;
        top: 40px;
        width: 375px;
        z-index: 100;
        @include autoAlpha(0);
        transition: all 0.3s $easeOutQuart;

        ul {
            width: 100%;
            padding: 24px 34px;
            background-color: white;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

            li {
                border-top: 1px solid #E9E9E9;

                a {
                    padding: 20px 0;
                    display: flex;
                    align-items: center;

                    .logo {
                        flex: 0 0 auto;
                        width: 40px;
                        height: 40px;

                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                            object-position: center center;
                        }
                    }

                    .metas {
                        margin-left: 15px;

                        .name {
                            @include druk-medium(13px, 13px);
                            color: $black;
                            transition: color 0.6s $easeOutQuart;
                        }

                        .description {
                            font-size: 16px;
                            line-height: 20px;
                            margin-top: 4px;
                            transition: color 0.6s $easeOutQuart;
                        }
                    }
                }

                &:first-child {
                    border-top: none
                }

                html.desktop & {
                    &:hover {
                        .metas {
                            .name,
                            .description {
                                color: var(--main-bg-color);
                            }
                        }
                    }
                }
            }
        }
    }

    html.desktop & {
        &:hover {
            .select-companies__list {
                @include autoAlpha(1);
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #header {
        display: none;
    }

    #header-mobile {
        display: block;
    }
}