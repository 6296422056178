.block-support {
    background-color: $black;
    color: white;

    .bg-img {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(0, 50%);
        pointer-events: none;
        z-index: 1;
    }

    .row {
        z-index: 2;
    }

    &:after {
        position: absolute;
        pointer-events: none;
        left: 0;
        bottom: 0;
        content: "";
        background-color: white;
        width: 100%;
        height: 94px;
        z-index: 1;
    }

    .grid {
        padding-top: 100px;
    }

    .r-2 {
        margin-top: 60px;
        z-index: 2;

        .button-arrow-border {
            margin-top: 14px;

            &:first-child {
                margin-top: 0;
            }
        }

        .column-6 {
            @include set-grid-prop(margin-left, 1, 0);

            &:first-child {
                margin-left: 0;
            }
        }

        .img-responsive {
            margin-top: 30px;
            width: 100%;
            border-top: 2px solid var(--main-bg-color);
        }
    }

    .quote {
        margin-top: 30px;
        border: 1px solid rgba(255, 255, 255, 0.3);

        &:first-child {
            margin-top: 0;
        }

        &.white {
            background-color: white;
            color: $black;
            border-color: $black;

            .quote__metas {
                border-color: $black;

                .function {
                    border-color: $black;
                }
            }
        }

        .quote__portrait {
            position: relative;
            width: 33%;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            &:before {
                content: '';
                position: relative;
                display: block;
                width: 100%;
                padding-top: 100%;
            }

            .quote__portrait-wrapper {
                @include full-absolute;

                &:after {
                    content: "";
                    @include full-absolute;
                    pointer-events: none;
                    background: transparent url('../images/common/trame.png') no-repeat center top;
                    background-size: 1310px 678px;
                    opacity: 0.2;
                    z-index: 2;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .quote__content {
            position: relative;
            margin-top: 30px;
            padding: 0 40px;
            @include druk-medium(24px, 32px);

            .quote-left {
                position: absolute;
                font-size: 90px;
                left: 50%;
                transform: translate(-100%, -70px);
            }

            .quote-right {
                display: inline-block;
                vertical-align: middle;
                font-size: 60px;
                margin-left: 10px;
                transform: translate(0, 20px);
            }
        }

        .quote__metas {
            margin-top: 100px;
            @include din-medium(14px, 14px);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            .name,
            .function {
                padding: 14px 30px;
            }

            .function {
                border-left: 1px solid rgba(255, 255, 255, 0.3);
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .bg-img {
            display: none;
        }

        .grid {
            padding: 60px 0;
        }

        .column-6 {
            margin-top: 20px;
        }

        .quote {

            .quote__content {
                padding: 0 20px;
                font-size: 20px;
                line-height: 26px;
            }

            .quote__metas {
                margin-top: 50px;
                flex-direction: column;
                align-items: flex-start;

                div {
                    width: 100%;
                    padding-left: 20px;
                }

                .function {
                    border-left: none;
                    border-top: 1px solid $black;
                }
            }
        }
    }
}