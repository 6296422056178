#solution {

    .section-2 {
        padding: 100px 0;
        overflow: hidden;
        border-bottom: 1px solid $black;

        .bg-img {
            position: absolute;
            pointer-events: none;
            left: 0;
            bottom: 0;
            transform: translate(-60%, 65%);
            z-index: 1;
        }

        &:after {
            content: "";
            background-color: $black;
            height: 100%;
            width: 1px;
            position: absolute;
            left: 50%;
            top: 0;
            pointer-events: none;
            z-index: 1;
        }

        .row {
            z-index: 2;
        }

        .r-1 {

            .c-1 {
                padding-right: 60px;
            }

            .c-2 {
                @include set-grid-prop(margin-left, 1, 0);
            }
        }

        .r-2 {
            margin-top: 60px;

            .c-2 {
                @include set-grid-prop(margin-left, 1, 0);
            }
        }

    }

    .section-3 {
        background-color: var(--main-bg-color);

        .column-6 {
            @include set-grid-prop(width, 5.5, 6);
        }

        .row {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            .content {
                display: flex;
                align-items: center;
                background-color: var(--main-bg-color);
                padding: 60px 0;
            }

            .illustration {

                .img-container {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 50vw;
                    height: 100%;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .r-1 {

            .content {
                padding-right: 60px;
            }

            .illustration {
                border-left: 1px solid $black;
            }
        }

        .r-2 {
            border-top: 1px solid $black;

            .content {
                padding-left: 60px;
            }

            .illustration {
                border-right: 1px solid $black;

                .img-container {
                    right: 0;
                    left: auto;
                }
            }
        }
    }

    .section-4 {
        padding: 100px 0;
        overflow: hidden;

        &:after {
            content: "";
            background-color: $black;
            height: 100%;
            width: 1px;
            position: absolute;
            left: 50%;
            top: 0;
            pointer-events: none;
            z-index: 1;
        }

        .row {
            z-index: 2;
        }

        .bg-img {
            position: absolute;
            pointer-events: none;
            left: 0;
            bottom: 0;
            transform: translate(-60%, 65%);
            z-index: 1;
        }

        .c-2 {
            @include set-grid-prop(margin-left, 1, 0);
        }
    }

    @media screen and (max-width: $mobile) {
        .section-2 {
            padding: 60px 0;

            .bg-img {
                display: none;
            }

            &:after {
                display: none;
            }

            .r-1 {

                .c-2 {
                    margin-top: 30px;
                }
            }
        }

        .section-3 {

            .grid {
                width: 100%;
            }

            .row {
                flex-direction: column;
            }

            .content {
                order: 2;
                padding: 40px 20px !important;
            }

            .illustration {
                order: 1;
                height: 348px;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 1px solid $black;

                .img-container {
                    left: 0 !important;
                    width: 100% !important;
                    height: 100%;
                }
            }
        }

        .section-4 {
            padding: 60px 0;

            .bg-img {
                display: none;
            }

            &:after {
                display: none;
            }

            .c-2 {
                margin-top: 60px;
            }
        }
    }

}