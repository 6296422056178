/**
TITLE
 */
h1 {
    @include druk-bold(46px, 48px);
    text-transform: uppercase;
}

h2 {
    @include druk-medium(24px, 32px, -0.032em);
}

.h2-title {

    h2 {
        /*display: inline-block;
        vertical-align: middle;*/

    }

    .title-line {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 3px;
        margin-left: 10px;
        background-color: var(--main-bg-color);
    }
}

/**
TEXT CONTENT
 */
.text-content {

    h1 {
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        position: relative;
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }

        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            height: 3px;
            margin-left: 10px;
            background-color: var(--main-bg-color);
        }
    }

    h3 {
        @include druk-medium(18px, 24px);
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul,
    ol,
    p {
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {

        li {
            position: relative;
            padding-left: 30px;
            margin-top: 10px;

            &:before {
                content: "";
                width: 6px;
                height: 6px;
                background-color: black;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: 6px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    strong {
        @include din-medium();
        font-weight: normal;
    }

    &.white {
        color: white;

        h2 {

            &:after {
                background-color: white;
            }
        }
    }
}

/**
TRAME
 */
.with-trame {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        @include full-absolute;
        pointer-events: none;
        background: transparent url('../images/common/trame.png') no-repeat center top;
        background-size: 1310px 678px;
        opacity: 0.2;
        z-index: 2;
        transition: opacity 0.6s $easeOutQuart;
    }
}

/**
BLOCK 4 CONTENT
 */
.b-4-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .b-4 {
        float: none;
    }

}
.b-4 {
    position: relative;
    @include set-grid-prop(margin-left, 1, 0);
    @include set-grid-prop(margin-top, 1, 0);
    overflow: hidden;
    border: 1px solid $black;
    background-color: white;

    &.has-link {
        border-top-color: var(--main-bg-color);
        border-top-width: 2px;
    }

    &:first-child,
    &:nth-child(3n + 1) {
        margin-left: 0;
    }

    a {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;

        html.desktop & {

            &:hover {

                .thumbnail {
                    &:after {
                        opacity: 0;
                    }

                    img {
                        transform: scale(1.05) rotate(0.05deg);
                    }
                }

                .button-arrow-border {
                    background-color: var(--main-bg-color);
                    //border-color: white;
                    color: white;

                    .icon {
                        //border-color: white;

                        svg {
                            stroke: white;
                            transform: translate(5px, 0);
                        }
                    }
                }
            }
        }
    }

    .thumbnail {
        width: 100%;
        height: 220px;
        overflow: hidden;
        border-bottom: 1px solid $black;

        /*&:after {
            content: "";
            @include full-absolute;
            pointer-events: none;
            background: transparent url('../images/common/trame.png') no-repeat center top;
            background-size: 1310px 678px;
            opacity: 0.2;
            z-index: 2;
            transition: opacity 0.6s $easeOutQuart;
        }*/

        img {
            position: relative;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 0.6s $easeInOutQuart;
            z-index: 1;
        }

        &.center {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                object-fit: unset;
                display: block;
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }
    }

    .text-content {
        padding: 24px;

        .title {
            @include druk-medium(13px, 22px);
            text-transform: uppercase;
        }

        .description {
            margin-top: 20px;
        }
    }

    .button-arrow-border {
        margin-top: auto;
        pointer-events: none;
    }

    @media screen and (max-width: $mobile) {
        margin-top: 30px;
        @include set-grid-prop(width, 5, 6);
        @include set-grid-prop(margin-left, 1, 0);

        &:first-child,
        &:nth-child(2n + 1) {
            margin-left: 0;
        }

        &:nth-child(3n + 1):not(:first-child) {
            @include set-grid-prop(margin-left, 1, 0);
        }

        .thumbnail {
            height: 180px;

            &.center {
                img {
                    object-fit: contain;
                    height: 70%;
                    width: 70%;
                }
            }
        }
    }
}

/**
ACCORDION
 */
.accordion-container {

    .accordion-item {
        border-top: 1px solid $black;

        &.open {
            .accordion-item__head {
                color: var(--main-bg-color);
            }
        }

        .accordion-item__head {
            cursor: pointer;
            position: relative;
            height: 100px;
            padding: 0 80px 0 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: color 0.3s $easeOutQuart;

            .title {
                @include druk-medium(18px, 24px);
            }

            svg {
                position: absolute;
                width: 20px;
                height: auto;
                right: 30px;
                top: 50%;
                transform: translate(0, -50%);
                fill: var(--main-bg-color);
            }
        }

        .accordion-item__content {
            position: relative;
            height: 0;
            overflow: hidden;

            .accordion-item__content-inner {
                padding: 20px 30px;
            }
        }

        .text-content {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }

        .list-documents {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }

            a {
                display: flex;
                align-items: center;

                .icon {
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    background-color: var(--main-bg-color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .name {
                    @include druk-medium(13px, 18px);
                    margin-left: 10px;
                    transition: color 0.6s $easeOutQuart;
                }

                svg {
                    width: 10px;
                    height: 11px;
                }

                html.desktop & {

                    &:hover {
                        .name {
                            color: var(--main-bg-color);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .accordion-item {

            .accordion-item__head {
                padding-left: 20px;

                .title {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .accordion-item__content {
                .accordion-item__content-inner {
                    padding: 20px;
                }
            }
        }
    }
}

/**
INFO BULLES
 */
.gm-style .gm-style-iw-c {
    background-color: $black;
    border-radius: 0;
    border: 1px solid white;
    padding: 0 !important;
    max-width: 320px !important;

    button {
        right: 2px !important;
        top: 2px !important;
        position: absolute;
        z-index: 2;
        width: 14px;
        height: 13px;
        background: transparent url('../images/common/close-cross.png') no-repeat center center !important;
        background-size: cover;

        img {
            display: none !important;
        }
    }

    .gm-style-iw-d {
        padding: 0 !important;
        overflow: hidden !important;

        .content__inner {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 26px 40px 26px 26px;
        }

        p {
            margin-top: 26px;
            @include din-medium(16px, 22px);

            &:first-child {
                margin-top: 0;
            }

            strong {
                @include druk-medium(13px, 17px);
                text-transform: uppercase;
            }
        }

        .button-rect {
            margin-left: auto;
            margin-top: 26px;
        }
    }
}

#infobulle {
    background-color: $black;
}

@media screen and (max-width: $mobile) {
    h1 {
        font-size: 28px;
        line-height: 30px;
    }

    h2 {
        font-size: 20px;
        line-height: 26px;
    }
}