.block-jobs {
    background-color: white;
    padding: 100px 0;
    z-index: 2;

    .r-1 {

        .column-5 {
            @include set-grid-prop(margin-left, 3, 2);

            &:first-child {
                margin-left: 0;
            }

            .text-content {
                margin-top: 30;
            }

            .button-rect {
                margin-top: 30px;
            }
        }
    }

    .r-2 {
        margin-top: 60px;

        .swiper-container {
            overflow: visible;
        }

        .swiper-slide {
            border: 1px solid $black;
            box-sizing: border-box;
            height: auto;

            a {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                height: 100%;

                html.desktop & {

                    &:hover {

                        .button-arrow-border {
                            background-color: var(--main-bg-color);
                            border-color: white;
                            color: white;

                            .icon {
                                border-color: white;

                                svg {
                                    stroke: white;
                                    transform: translate(5px, 0);
                                }
                            }
                        }
                    }
                }
            }

            .thumbnail {
                width: 100%;
                height: 220px;
                overflow: hidden;

                &:after {
                    content: "";
                    @include full-absolute;
                    pointer-events: none;
                    background: transparent url('../images/common/trame.png') no-repeat center top;
                    background-size: 1310px 678px;
                    opacity: 0.2;
                    z-index: 2;
                    transition: opacity 0.6s $easeOutQuart;
                }

                img {
                    position: relative;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: transform 0.6s $easeInOutQuart;
                    z-index: 1;
                }
            }

            .title {
                @include druk-medium(13px, 22px);
                padding: 24px;
                text-transform: uppercase;
            }

            .button-arrow-border {
                margin-top: auto;
            }

            html.desktop & {

                &:hover {

                    .thumbnail {
                        &:after {
                            opacity: 0;
                        }

                        img {
                            transform: scale(1.05) rotate(0.05deg);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-1 {

            .text-content {
                margin-top: 30px;
            }
        }

        .r-2 {
            .swiper-navigation {
                display: none;
            }
        }
    }
}