#footer {
    position: relative;
    background-color: $black;
    color: white;
    z-index: 10;

    .footer__top {

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^="column"] {
                float: none;
            }

            .column-5 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h2 {
                    position: relative;

                    &:after {
                        position: absolute;
                        bottom: -16px;
                        left: 0;
                        content: "";
                        width: 30px;
                        height: 3px;
                        background-color: var(--main-bg-color);
                    }
                }

                .text-content {
                    margin-top: 60px;
                }

                .button-rect {
                    margin-top: 30px;
                }
            }

            .column-6 {
                position: relative;
                @include set-grid-prop(margin-left, 1.5, 1);
                height: 520px;

                #map {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 50vw;
                }
            }
        }
    }

    .footer__bottom {
        padding: 100px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.16);

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^="column"] {
                float: none;
            }

            .column-4 {
                @include set-grid-prop(width, 4, 4);

                .logo {

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .description {
                    margin-top: 30px;
                }
            }

            .c-2 {
                @include set-grid-prop(margin-left, 2, 2);

                li {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        @include druk-medium(13px, 13px);
                        border-bottom: 1px solid transparent;
                        transition: border-color 0.3s $easeOutQuart;
                        padding-bottom: 4px;

                        html.desktop & {
                            &:hover {
                                border-color: white;
                            }
                        }
                    }
                }
            }

            .c-3 {
                @include set-grid-prop(margin-left, 1, 0);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .side-menu {
                    li {
                        margin-top: 40px;

                        &:first-child {
                            margin-top: 0;
                        }

                        a {
                            @include druk-medium(13px, 13px);
                            border-bottom: 1px solid transparent;
                            transition: border-color 0.3s $easeOutQuart;
                            padding-bottom: 4px;

                            html.desktop & {
                                &:hover {
                                    border-color: white;
                                }
                            }
                        }
                    }
                }

                .phone-number {
                    margin-top: 40px;

                    .button-rect {
                        margin-top: 20px;
                    }
                }

                .socials {
                    margin-top: auto;

                    ul {
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        li {
                            margin-left: 20px;

                            &:first-child {
                                margin-left: 0;
                            }

                            a {
                                display: block;
                            }

                            svg {
                                fill: white;
                                width: 20px;
                                height: auto;
                            }

                            &.youtube {
                                margin-top: 4px;
                                svg {
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer__credits {
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        padding: 30px 0;

        .row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        [class^="column"] {
            float: none;
        }

        .column-6 {

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {

                    a {
                        display: block;
                        @include din-medium(10px, 10px, 0.136em);
                        text-transform: uppercase;
                        padding: 0 10px;
                        border-left: 1px solid white;
                    }

                    &:first-child {

                        a {
                            padding-left: 0;
                            border-left: 0;
                        }
                    }
                }
            }
        }

        .column-3 {
            margin-left: auto;
            @include din-medium(10px, 10px, 0.136em);
            color: white;
            opacity: 0.3;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: $mobile) {
        .footer__top {

            .bg-line.l-2 {
                display: none;
            }

            .r-1 {
                padding: 60px 0;

                .column-6 {
                    display: none;
                }
            }
        }

        .footer__bottom {
            padding: 60px 0;

            .r-1 {
                flex-direction: column;

                [class^="column"] {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .c-3 {
                    margin-top: 30px;

                    .socials {
                        width: 100%;
                        margin-top: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        ul {
                            margin-left: auto;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .footer__credits {

            .r-1 {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .column-6 {

                    ul {
                        flex-direction: column;
                        align-items: flex-start;

                        li {
                            flex: 1 0 auto;
                            margin-top: 10px;
                            margin-left: 0;

                            a {
                                padding: 0;
                                border-left: 0;
                            }

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }

                .column-3 {
                    margin-top: 30px;
                    text-align: left;
                }
            }
        }
    }
}