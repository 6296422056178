#single-metier {

    .section-head {
        .text-content {
            //display: none;
        }
    }

    .section-2 {
        overflow: hidden;

        .bg-img {
            position: absolute;
            pointer-events: none;
            left: 0;
            bottom: 0;
            transform: translate(-60%, 65%);
            z-index: 1;
        }

        &:after {
            position: absolute;
            left: 50%;
            top: 0;
            pointer-events: none;
            content: "";
            background-color: $black;
            height: 100%;
            width: 1px;
        }

        .r-1 {

            .column-6 {
                @include set-grid-prop(width, 5.5, 6);
                border-left: 1px solid $black;
                border-bottom: 1px solid $black;
                display: flex;
                align-items: center;
                height: 76px;

                div {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 25px;
                    border-right: 1px solid $black;
                    @include din-medium(16px, 18px);

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .r-2 {
            margin-top: 100px;
            padding-bottom: 100px;

            .row {
                z-index: 2;
            }

            .c-1 {
                .text-content {
                    padding-right: 60px;
                }
            }

            .c-2 {
                @include set-grid-prop(margin-left, 1, 0);
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .section-2 {

            .bg-img {
                display: none;
            }

            &:after {
                display: none;
            }

            .grid {
                width: 100%;

                .r-1 {

                    .column-6 {
                        flex-direction: column;
                        border-left: none;
                        border-bottom: none;
                        height: auto;

                        .code,
                        .channel {
                            border-right: none;
                            border-bottom: 1px solid $black;
                            width: 100%;
                            padding: 26px 20px;
                            text-align: center;
                            height: 100%;
                        }
                    }
                }

                .r-2 {
                    margin-top: 0;
                    padding: 60px 20px;

                    .c-2 {
                        margin-top: 60px;
                    }
                }
            }
        }
    }

}