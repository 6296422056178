#team {

    .section-2 {
        padding: 120px 0 150px 0;

        .r-1 {

            .team-container__inner {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

            [class^='column'] {
                float: none;
                flex: 0 0 auto;
            }
        }

        .r-2 {
            margin-top: 50px;

            a {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .text {
                    @include druk-medium(13px, 13px);
                    color: $black;
                }

                svg {
                    margin-left: 20px;
                    width: auto;
                    height: 10px;
                }
            }
        }

        .column-4 {
            position: relative;
            @include set-grid-prop(margin-left, 1, 0);
            @include set-grid-prop(margin-top, 1, 0);
            overflow: hidden;
            border: 1px solid $black;

            &.has-bio {
                cursor: pointer;
            }

            &:first-child,
            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            .thumbnail {
                width: 100%;
                height: 280px;
                overflow: hidden;

                img {
                    position: relative;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: transform 0.6s $easeInOutQuart;
                    z-index: 1;
                }
            }

            .team-item__metas {
                padding: 30px;

                .team-item__metas-title {
                    @include druk-medium(18px, 18px);
                }

                .team-item__metas-subtitle {
                    margin-top: 12px;
                    @include din-medium(18px, 20px);
                }
            }

            .team-item__button {
                border-top: 1px solid $black;
                height: 60px;

                a {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    transition: all 0.6s $easeOutQuart;
                }

                .team-item__button-title {
                    @include druk-medium(10px, 10px);
                    padding-left: 30px;
                }

                .team-item__button-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 59px;
                    border-left: 1px solid $black;
                    margin-left: auto;
                    transition: border-color 0.6s $easeOutQuart;

                    svg {
                        width: 25px;
                        height: auto;
                        fill: var(--main-bg-color);
                        transition: fill 0.6s $easeOutQuart;
                    }
                }

                html.desktop & {
                    &:hover {

                        a {
                            background-color: var(--main-bg-color);
                            color: white;

                            .team-item__button-icon {
                                border-left-color: white;

                                svg {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    @media screen and (max-width: $mobile) {
        .section-2 {
            padding: 60px 0;

            .column-4 {
                margin-top: 30px;
                @include set-grid-prop(width, 5, 6);
                @include set-grid-prop(margin-left, 1, 0);
                @include set-grid-prop(margin-top, 1, 0);

                &:first-child,
                &:nth-child(2n + 1) {
                    margin-left: 0;
                }

                &:nth-child(3n + 1):not(:first-child) {
                    @include set-grid-prop(margin-left, 1, 0);
                }

                .thumbnail {
                    height: 100px;
                }

                .team-item__metas {
                    padding: 20px 10px;

                    .team-item__metas-title {
                        font-size: 12px;
                        line-height: 14px;
                    }

                    .team-item__metas-subtitle {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                .team-item__button {

                    .team-item__button-title {
                        font-size: 8px;
                        line-height: 8px;
                        padding-left: 10px;
                    }

                    .team-item__button-icon {
                        display: none;
                    }
                }
            }
        }
    }
}

.modal {
    transform: translate(100%, 0);
    position: fixed;
    width: 90vw;
    height: 100vh;
    background-color: var(--main-bg-color);
    z-index: 200;
    top: 0;
    right: 0;

    .modal__inner {
        height: calc(100% - 100px);
        width: calc(100% - 100px);
        margin-top: 50px;
        margin-left: 50px;
        background-color: white;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        overflow: hidden;
    }

    .modal__thumbnail {
        flex: 0 0 auto;
        width: 32%;

        .img-container {
            border-bottom: 1px solid $black;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .modal__bio {
        height: 100%;
        border-left: 1px solid $black;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .modal__bio-inner {
            padding: 0 15%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .name {
            @include druk-medium(24px, 32px, -0.038em);
        }

        .function {
            @include din-medium(18px, 24px);
            margin-top: 38px;
        }

        .text-content {
            margin-top: 60px;
        }
    }

    .modal__close {
        position: absolute;
        top: 30px;
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 3;
        cursor: pointer;

        .legend {
            @include druk-medium(12px, 12px);
            transition: color 0.6s $easeOutQuart;
        }

        .icon {
            margin-left: 14px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border: 1px solid $black;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            transition: border-color 0.6s $easeOutQuart;

            svg {
                width: 14px;
                height: 14px;
                stroke: $black;
                transition: transform 0.6s $easeOutQuart, stroke 0.6s $easeOutQuart;
            }
        }

        html.desktop & {

            &:hover {

                .legend {
                    color: var(--main-bg-color);
                }

                .icon {
                    border-color: var(--main-bg-color);

                    svg {
                        transform: rotate(90deg);
                        stroke: var(--main-bg-color);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        width: 100vw;

        .modal__close {
            top: 0;
            right: 0;
            transform: scale(0.8);

            .legend {
                display: none;
            }
        }

        .modal__inner {
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-top: 20px;
        }

        .modal__bio {
            justify-content: flex-start;
            overflow-y: scroll;

            .modal__bio-inner {
                padding: 40px 20px;
            }

            .name {
                font-size: 18px;
                line-height: 1.2;
            }

            .function {
                margin-top: 20px;
            }

            .text-content {
                margin-top: 30px;
            }
        }
    }
}