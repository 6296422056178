.gfield_radio {
    display: flex;
    align-items: center;

    .gchoice {
        margin-left: 20px;

        label {
            //margin-top: 4px;
            @include druk-medium(13px, 13px);
            margin-left: 10px;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.gform_validation_errors {
    display: none !important;
}

.gfield {
    margin-top: 30px;
}

.ginput_container_email,
.ginput_container_phone,
.ginput_container_text {
    height: 64px;
    border: 1px solid $black;
    background-color: white;
    @include druk-medium(13px, 62px);
    color: $black;

    input {
        padding: 0 20px !important;
        height: 100%;
    }
}

.ginput_container_textarea {

    textarea {
        border: 1px solid $black;
        background-color: white;
        @include druk-medium(13px, 62px);
        color: $black;
        resize: none;
        padding: 20px !important;
        height: 100%;
        line-height: 22px;
    }
}

.gform_wrapper.gravity-theme .gfield_label {
    margin-bottom: 22px !important;
    @include din-medium(14px, 14px);
    font-weight: normal !important;
}

.gform_confirmation_message {
    @include druk-medium(20px, 28px);
    text-align: center;
}

.gform_footer {
    text-align: right;
    margin-top: 20px;
}

input[type="submit"] {
    color: white;
    display: inline-block;
    border: 1px solid var(--main-bg-color);
    text-transform: uppercase;
    padding: 14px 24px 12px 24px;
    @include druk-medium(13px, 13px);
    background-color: var(--main-bg-color);
    transition: color 0.6s $easeOutQuart, border-color 0.6s $easeOutQuart, background-color 0.6s $easeOutQuart;

    html.desktop & {
        &:hover {
            color: var(--main-bg-color);
            background-color: white;
        }
    }
}

.gfield_checkbox,
.gfield_radio {

    input {
        position: absolute;
        width: 20px;
        height: 20px;
        margin-top: 0;
        z-index: 10;
        opacity: 0.0000001;

        &:checked {
            + label {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    label {
        margin-left: 0 !important;
        cursor: pointer;
        padding-left: 30px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid var(--main-bg-color);
            transform: translate(0, -50%);
            z-index: 1;
            transition: all 0.4s $easeOutQuart;
        }

        &:after {
            content: "";
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 5px;
            width: 10px;
            height: 10px;
            transform: translate(0, -50%);
            border-radius: 50%;
            background: var(--main-bg-color);
            z-index: 1;
            transition: all 0.4s $easeOutQuart;
        }
    }
}

.gfield_checkbox {

    label {
        padding-top: 6px;
        padding-left: 40px;

        &:before {
            border-radius: 0;
        }

        &:after {
            border-radius: 0;
        }
    }
}

