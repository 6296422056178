#agent {

    .section-2 {

        &:before {
            content:"";
            pointer-events: none;
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--main-bg-color);
            width: 50vw;
            height: 100%;
        }

        .row {
            z-index: 2;
        }

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^="column"] {
                float: none;
            }
        }

        .c-1 {
            color: white;
            padding: 100px 7% 100px 0;
            background-color: var(--main-bg-color);
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            .button-rect {
                margin-left: auto;
                margin-top: auto;
            }
        }

        .c-2 {
            padding: 170px 7%;
            @include set-grid-prop(margin-left, 1, 0);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .button-rect {
                margin-top: 30px;
            }

            .button-line {
                margin-top: 60px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .section-2 {

            .grid {
                width: 100%;
            }

            .c-1 {
                padding: 60px 20px;

                .button-rect {
                    margin-top: 30px;
                }
            }

            .c-2 {
                padding: 60px 20px;
            }

            &:before {
                display: none;
            }

            .row {
                flex-direction: column;
            }
        }
    }
}