/*********************
BASE
*********************/
@import "base/normalize";
@import "base/reset";
@import "base/ress";

/*********************
HELPERS
*********************/
@import "helpers/functions";
@import "helpers/easing";
@import "helpers/helpers";
/*@import "helpers/locomotive-scroll";*/

/*********************
COMMON
*********************/
@import "common/grid";
@import "common/fonts";
@import "common/colors";
@import "common/buttons";
@import "common/animations";
@import "common/form";
@import "common/content";

/*********************
HEADER
*********************/
@import "components/header";

/*********************
FOOTER
*********************/
@import "components/footer";

/*********************
PAGE
*********************/
@import "layouts/page";

/*********************
PARTIALS
*********************/
@import "partials/partials";
@import "partials/block-slider";
@import "partials/block-support";
@import "partials/block-key-numbers";
@import "partials/block-jobs";
@import "partials/block-certifications";

/*********************
PAGES
*********************/
@import "pages/home";
@import "pages/team";
@import "pages/group";
@import "pages/formation";
@import "pages/solution";
@import "pages/metier";
@import "pages/jobs";
@import "pages/contact";
@import "pages/agent";
@import "pages/common";