.block-slider {
    padding: 120px 0;

    .r-1 {
        margin-bottom: 70px;

        .c-2 {
            @include set-grid-prop(margin-left, 3, 2);
        }
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-wrapper {
        //align-items: stretch;
    }

    .swiper-slide {
        border: 1px solid $black;
        box-sizing: border-box;
        height: auto;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .thumbnail {
            width: 100%;
            height: 340px;
            overflow: hidden;

            &:after {
                content: "";
                @include full-absolute;
                pointer-events: none;
                background: transparent url('../images/common/trame.png') no-repeat center top;
                background-size: 1310px 678px;
                opacity: 0.2;
                z-index: 2;
                transition: opacity 0.6s $easeOutQuart;
            }

            img:not(.logo) {
                position: relative;
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform 0.6s $easeInOutQuart;
                z-index: 1;
            }

            .logo {
                @include center-xy;
                width: auto;
                height: auto;
                max-height: 60%;
                max-width: 60%;
                z-index: 4;
            }
        }

        .title {
            @include druk-medium(13px, 22px);
            padding: 24px;
            text-transform: uppercase;
        }

        .description {
            border-top: 1px solid $black;
            padding: 24px;
        }

        html.desktop & {

            &:hover {

                .thumbnail {
                    &:after {
                        opacity: 0;
                    }

                    img:not(.logo) {
                        transform: scale(1.05) rotate(0.05deg);
                    }
                }

                .button-arrow-border {
                    background-color: var(--main-bg-color);
                    //border-color: white;
                    color: white;

                    .icon {
                        //border-color: white;

                        svg {
                            stroke: white;
                            transform: translate(5px, 0);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 60px 0;

        .r-1 {

            .c-2 {
                .text-content {
                    margin-top: 30px;
                }
            }
        }

        .swiper-slide {

            .thumbnail {
                height: 176px;
            }

            .title {
                font-size: 11px;
                line-height: 22px;
            }
        }

        .swiper-navigation {
            display: none;
        }
    }
}