#home {

    /**
    SECTION HERO
     */
    .section-hero {
        //max-height: 700px;
        //height: calc(100vh - 140px);
        padding: 50px 0;
        overflow: hidden;

        .bg-line {
            top: auto;
            bottom: 0;
            z-index: 4;

            &.outside-left,
            &.outside-right {
                height: 360px;
            }

            &.l-1 {
                height: 100px;
            }

            &.l-2 {
                height: 360px;
            }

            &.l-3 {
                height: 100px;
            }
        }

        .grid,
        .row,
        .column-5 {
            height: 100%;
        }

        .faces {
            @include full-absolute;
            width: 45vw;
            z-index: 2;

            .face {
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0;
                max-width: 100%;

                &:first-child {
                    opacity: 1;
                }
            }
        }

        .logo {
            position: absolute;
            left: 0;
            top: 80px;
            z-index: 1;
            transform: translate(-40%, 0);
        }

        .column-5 {
            @include set-grid-prop(margin-left, 6, 6);
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            position: relative;
            z-index: 2;

            p {
                margin-top: 24px;
                font-size: 18px;
                line-height: 24px;
            }

            .button-line {
                margin-top: 30px;
            }
        }
    }

    /**
    SECTION 2
     */
    .section-2 {
        background-color: $black;

        .grid {
            padding: 60px 0;
        }

        .column-3 {
            color: white;
            @include set-grid-prop(margin-left, 2, 1);

            &:first-child {
                margin-left: 0;
            }

            .icon {
                width: 42px;
                height: 42px;
                border: 3px solid white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 1px;

                img {
                    flex: 0 0 auto;
                    object-fit: contain;
                    width: 75%;
                    //height: 70%;
                }
            }

            .title {
                margin-top: 30px;
                @include druk-medium(17px, 24px);
            }

            .description {
                margin-top: 30px;
                font-size: 16px;
                line-height: 20px;
                padding-left: 14px;
                border-left: 1px solid white;
            }

            .button-line {
                margin-top: 30px;
            }
        }
    }

    /**
    SECTION 4
     */
    .section-4 {

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            pointer-events: none;
            background-color: $black;
            height: 94px;
            width: 1px;
            z-index: 2;
        }
    }
}

@media screen and (max-width: $mobile) {
    #home {

        /**
        SECTION HERO
         */
        .section-hero {
            display: flex;
            flex-direction: column;
            height: auto;
            max-height: 100%;

            .bg-line {
                display: none;
            }

            .faces {
                @include full-relative;
                height: 440px;
                order: 1;
                overflow: hidden;
                transform: translate(-20%, 0);
            }

            .logo {
                top: 100px;
                order: 2;
                width: 200% !important;
                transform: translate(-50%, 0);

                .img-responsive__item {

                    img {
                        object-fit: contain;
                        object-position: top center;
                    }
                }
            }

            .grid {
                order: 3;
                border-top: 1px solid $black;
                background-color: white;
                width: 100%;
                padding: 50px 20px;
                z-index: 2;
            }
        }

        /**
        SECTION 2
         */
        .section-2 {
            .column-3 {
                margin-top: 50px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        /**
        SECTION 4
         */
        .section-4 {

            &:before {
                display: none;
            }
        }

        .section-5 {

            .partial {
                padding-top: 0;
            }
        }
    }
}
