/**
BUTTON RECT
 */
.button-rect {

    .button-rect__inner,
    a {
        display: inline-block;
        border: 1px solid var(--main-bg-color);
        text-transform: uppercase;
        padding: 14px 24px 12px 24px;
        @include druk-medium(13px, 13px);
        transition: color 0.6s $easeOutQuart, border-color 0.6s $easeOutQuart, background-color 0.6s $easeOutQuart;

        .text {
            transition: color 0.6s $easeOutQuart;
        }

        html.desktop & {
            &:hover {
                color: white;
                background-color: var(--main-bg-color);
            }
        }
    }

    &.red {

        .button-rect__inner,
        a {
            background-color: var(--main-bg-color);

            html.desktop & {
                &:hover {
                    background-color: white;

                    .text {
                        color: var(--main-bg-color);
                    }
                }
            }
        }
    }

    &.white {

        .button-rect__inner,
        a {
            background-color: white;
            color: var(--main-bg-color);
            border-color: white;

            html.desktop & {
                &:hover {
                    color: white;
                    background-color: var(--main-bg-color);
                }
            }
        }
    }
}

/**
BUTTON LINE
 */
.button-line {

    a {
        display: inline-block;
        @include druk-medium(13px, 13px);
        padding-bottom: 4px;
        position: relative;
        transition: color 0.6s $easeOutQuart;

        &:before {
            content: "";
            background-color: var(--main-bg-color);
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
        }

        html.desktop & {
            &:hover {

                &:before {
                    transform: scaleX(.8);
                }
            }
        }
    }

    &.white {
        color: white;
    }
}

/**
ARROW
 */
.button-arrow-border {
    border: 1px solid $black;
    height: 60px;
    width: 100%;
    transition: all 0.6s $easeOutQuart;

    &.no-border {
        border: none;
        border-top: 1px solid $black;
    }

    .button-arrow-border__inner,
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;
    }

    &.white {
        color: white;
        border-color: white;

        .icon {
            border-color: white;

            svg {
                stroke: white;
            }
        }

        html.desktop & {

            &:hover {
                background-color: white;
                border-color: $black;
                color: $black;

                .icon {
                    border-color: $black;

                    svg {
                        stroke: $black;
                        transform: translate(5px, 0);
                    }
                }
            }
        }
    }

    .text {
        @include druk-medium(13px, 22px);
        text-transform: uppercase;
    }

    .icon {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 58px;
        border-left: 1px solid $black;
        transition: all 0.6s $easeOutQuart;

        svg {
            width: 18px;
            height: 18px;
            stroke: $black;
            transition: all 0.6s $easeOutQuart;
        }
    }

    html.desktop & {

        &:hover {
            background-color: $black;
            border-color: white;
            color: white;

            .icon {
                border-color: white;

                svg {
                    stroke: white;
                    transform: translate(5px, 0);
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {

    .button-arrow-border {
        height: 52px;

        a {
            padding-left: 10px;
        }

        .text {
            font-size: 11px;
            line-height: 14px;
            padding-right: 30px;
        }

        .icon {
            width: 52px;
            height: 50px;
        }
    }
}