.block-key-numbers {
    padding: 100px 0;
    border-bottom: 1px solid $black;
    z-index: 2;
    overflow: hidden;

    .bg-img {
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 0;
        transform: translate(-100%, -100px);
        z-index: 1;
    }

    &.with-title {

        .bg-img {
            top: 130px;
        }
    }

    .row {
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: -100px;
        height: calc(100% + 100px);
        width: 1px;
        background-color: $black;
    }

    .r-1 {
        margin-bottom: 70px;

        .column-4 {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    .r-2 {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-wrap: wrap;

        [class^='column'] {
            float: none;
        }
    }

    .column-5 {
        margin-top: 50px;
        @include set-grid-prop(margin-left, 1, 1);

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }

        .title {
            @include druk-bold(16px, 20px);
            text-transform: uppercase;
        }

        .number {
            margin-top: 20px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            -webkit-text-stroke: 1px var(--main-bg-color);
            color: transparent;

            .number__content {
                @include druk-medium(64px, 64px);
                transform: translate(0, 8px);
            }

            .number__value {
                margin-left: 10px;
                @include druk-bold(44px, 44px);
                text-transform: uppercase;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 60px;

        &:before {
            display: none;
        }

        .bg-img {
            transform: translate(-70%, 0);
        }

        .column-5 {
            margin-top: 30px !important;
            text-align: center;

            &:first-child {
                margin-top: 0 !important;
            }

            .title {
                font-size: 13px;
                line-height: 17px;
            }

            .number {
                justify-content: center;

                .number__content {
                    font-size: 44px;
                    line-height: 1;
                    transform: translate(0, 4px);
                }

                .number__value {
                    font-size: 30px;
                    line-height: 1;
                }
            }
        }
    }
}