#contact {

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .form-legend {
        padding: 30px 0;
        @include din-regular(14px, 16px);
    }

    .form-content {
        background-color: #F4F4F4;
        padding: 7%;
    }

    #field_1_10 {
        margin-top: 30px;
    }

    #field_1_10,
    #field_1_11 {
        label {
            @include din-regular(14px, 20px);
        }
    }

    .section-2 {
        padding-bottom: 100px;
    }
}