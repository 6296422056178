#group {

    .section-2 {
        border-bottom: 1px solid $black;

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^='column'] {
                float: none;
            }

            .content {
                @include set-grid-prop(width, 5.5, 6);

                .text-content {
                    padding-right: 10%;
                    padding-top: 110px;
                }

                .metas {
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    border-top: 1px solid $black;
                    border-left: 1px solid $black;
                    height: 80px;
                    margin-top: 60px;

                    .portrait {
                        width: 80px;
                        height: 80px;
                        border-right: 1px solid $black;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .name {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        @include din-medium(14px, 18px);
                        padding: 0 4%;
                    }

                    .function {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        @include din-medium(12px, 14px);
                        text-transform: uppercase;
                        border-left: 1px solid $black;
                        padding: 0 4%;
                    }
                }
            }

            .illustration {
                @include set-grid-prop(width, 5.5, 6);

                .img-container {
                    position: absolute;
                    height: 100%;
                    width: 50vw;
                    left: 0;
                    top: 0;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .section-3 {
        border-bottom: 1px solid $black;

        .grid {
            padding: 100px 0;
        }

        .r-1 {

            .c-2 {
                @include set-grid-prop(margin-left, 3, 2);
            }
        }

        .r-2 {
            margin-top: 60px;
        }
    }

    .section-5 {
        border-bottom: 1px solid $black;

        .r-1 {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;

            [class^='column'] {
                float: none;
            }

            .illustration {
                position: relative;
                padding: 100px 0;
                @include set-grid-prop(width, 5.5, 6);
                border-right: 1px solid $black;

                &:before {
                    position: absolute;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                    content: "";
                    width: 50vw;
                    height: 100%;
                    background-color: var(--main-bg-color);
                }

                .image {
                    margin-top: 60px;
                    width: calc(100% - 40px);
                    height: 440px;
                    overflow: hidden;
                    border: 1px solid $black;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: center;
                @include set-grid-prop(margin-left, 0.5, 0);
                padding: 0 7%;
            }
        }
    }

    .section-7 {
        border-top: 1px solid $black;
        padding: 120px 0;

        .row {
            z-index: 2;
        }

        .r-1 {
            margin-bottom: 70px;

            .c-2 {
                @include set-grid-prop(margin-left, 3, 2);
            }
        }

        .bg-img {
            position: absolute;
            pointer-events: none;
            left: 50%;
            bottom: 0;
            transform: translate(0, 70%);
            z-index: 1;
        }
    }

    @media screen and (max-width: $mobile) {

        .section-2 {

            .grid {
                width: 100%;
            }

            .r-1 {
                flex-direction: column;

                .content {
                    position: relative;
                    order: 2;
                    width: calc(100% - 40px) !important;
                    margin: 0 auto !important;

                    .text-content {
                        padding: 50px 0 0 0;
                    }

                    .metas {
                        margin-top: 40px;
                        flex-direction: column;
                        border: none;
                        height: auto;

                        .portrait {
                            border: 1px solid $black;
                            border-bottom: none;
                        }

                        .name,
                        .function {
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                        }

                        .name {
                            border: 1px solid $black;
                            border-bottom: none;
                        }

                        .function {
                            border: 1px solid $black;
                            border-bottom: none;
                        }
                    }
                }

                .illustration {
                    order: 1;
                    height: 330px;

                    .img-container {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .section-3 {

            .grid {
                padding: 60px 0;
            }
        }

        .section-5 {
            padding-bottom: 60px;

            .grid {
                width: 100%;
            }

            .r-1 {
                flex-direction: column;

                .illustration {
                    padding: 60px 0 0 0;
                    background-color: var(--main-bg-color);
                    border-right: none;

                    &:before {
                        display: none;
                    }

                    .text-content {
                        padding: 0 20px;
                    }

                    .image {
                        width: 100%;
                        height: 260px;
                    }
                }

                .content {
                    margin-top: 30px;
                }
            }
        }

        .section-7 {
            padding: 60px 0;

            .r-1 {
                .c-2 {
                    margin-top: 30px;
                }
            }
        }

    }

}