/**
BUBBLE CONTACT
 */
.bubble-contact {
    position: fixed;
    right: 60px;
    bottom: 60px;
    z-index: 100;
    width: 192px;
    height: 88px;

    body.page-template-tpl-contact & {
        display: none;
    }

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

    .bubble-contact__content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 18px 18px 18px 34px;
    }

    svg {
        position: absolute;
        width: 192px;
        height: 88px;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .text {
        @include druk-medium(13px, 16px);
        color: white;
        z-index: 2;
    }

    .button-rect {
        position: absolute;
        bottom: -23px;
        right: -12px;
        z-index: 3;
        box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}

/**
SWIPER NAVIGATION
 */
.swiper-navigation {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;

    .swiper-navigation__timeline {
        width: 330px;
        height: 1px;
        //overflow: hidden;
        background-color: rgba(0, 0, 0, 0.4);

        .swiper-navigation__timeline-inner {
            background-color: var(--main-bg-color);
            height: 1px;
            width: 100%;
            z-index: 1;
            transform-origin: 0 0;
            transition: transform 0.3s $easeInOutQuart;
            transform: scaleX(0);
        }
    }

    .swiper-navigation__button-prev,
    .swiper-navigation__button-next {
        border: 1px solid $black;
        width: 70px;
        height: 42px;
        display: flex;
        margin-left: 20px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: border-color 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        html.desktop & {

            &:hover {
                border-color: var(--main-bg-color);

                svg {
                    stroke: var(--main-bg-color);
                    transform: translate(5px, 0);
                }
            }
        }

        svg {
            width: 17px;
            height: 17px;
            stroke: $black;
            transition: stroke 0.6s $easeOutQuart, transform 0.6s $easeOutQuart;
        }
    }

    .swiper-navigation__button-prev {
        margin-left: 30px;

        svg {
            transform: rotate(-180deg);
        }

        html.desktop & {
            &:hover {

                svg {
                    transform: translate(-5px, 0) rotate(-180deg);
                }
            }
        }
    }
}