html {
    background-color: white;
}

body {
    position: relative;
    background-color: white;
    font-size: 100%;
    min-height: 100%;
    @include din-regular(18px, 24px);
    color: $black;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

svg,
img {
    display: block;
}

.page-tpl {
    padding-top: 140px;
}

/**
BG LINES
 */
.bg-line {
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.16);
    top: 0;
    pointer-events: none;

    &.outside-left {
        @include set-grid-prop(left, -0.5, 0);
    }

    &.outside-right {
        @include set-grid-prop(right, -0.5, 0);
    }

    &.l-1 {
        @include set-grid-prop(left, 2.5, 3);
    }

    &.l-2 {
        @include set-grid-prop(left, 5.5, 6);
    }

    &.l-3 {
        @include set-grid-prop(left, 8.5, 9);
    }

    &.black {
        background-color: rgba(0, 0, 0, 0.08);
    }
}

/**
SECTION HEAD
 */
.section-head {
    overflow: hidden;
    position: relative;
    min-height: 400px;
    border-bottom: 1px solid $black;

    .bg-line {
        bottom: 0;
        top: auto;

        &.l-1,
        &.l-3 {
            height: 130px;
        }
    }

    .grid,
    .row {
        min-height: 400px;
    }

    .column-6 {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 2;
        padding: 50px 0;

        h1 {
            font-size: 40px;
            line-height: 44px;
        }

        .text-content {
            margin-top: 30px;
        }
    }

    .bg-img {
        position: absolute;
        left: 50%;
        top: 30px;
        z-index: 1;
        pointer-events: none;

        &.white {
            display: none;
        }

        &.grey {
            display: none;
        }
    }

    .illustration {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
    }

    + section {
        background-color: white;
        z-index: 2;
    }

    .button-rect {
        //display: none;
        margin-top: 40px;
        color: white;
    }

    body.page-template-tpl-contact-agent &,
    body.page-template-tpl-agent &,
    body.page-template-tpl-jobs &,
    body.metier-template-default & {
        background-color: $black;
        color: white;

        .bg-line {
            background-color: rgba(255, 255, 255, 0.16);
        }

        .bg-img {
            display: none;

            &.white {
                display: block;
            }
        }
    }

    /*body.metier-template-default & {
        .button-rect {
            display: block;
        }
    }*/
}

@media screen and (max-width: $mobile) {
    .page-tpl {
        padding-top: 78px;
    }

    .bg-line {
        &.outside-left,
        &.outside-right {
            display: none;
        }

        &.l-1,
        &.l-3 {
            display: none;
        }
    }

    /**
    SECTION HEAD
     */
    .section-head {
        height: auto;

        .bg-line {
            display: none;
        }

        .grid {
            padding: 100px 0 50px 0;
        }

        .bg-img {
            width: 250vw !important;
            left: -50%;
            top: 0;

            &.color {
                display: none;
            }

            &.grey {
                display: block;
            }
        }

        .illustration {
            display: none;
        }

        .column-6 {

            h1 {
                font-size: 28px;
                line-height: 30px;
            }
        }
    }
}