@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

/* Webfont: DINNextW1G-Regular */@font-face {
    font-family: 'DINNextW1G';
    src: url('../fonts/DINNextW1G-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/DINNextW1G-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/DINNextW1G-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/DINNextW1G-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/DINNextW1G-Regular.svg#DINNextW1G-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: DINNextW1G-Medium */@font-face {
    font-family: 'DINNextW1GMedium';
    src: url('../fonts/DINNextW1G-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/DINNextW1G-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/DINNextW1G-Medium.woff') format('woff'), /* Modern Browsers */
    url('../fonts/DINNextW1G-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/DINNextW1G-Medium.svg#DINNextW1G-Medium') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {font-family: "DrukWide";
    src: url("../fonts/1238db2fc008ce88465a792fad1e5519.eot"); /* IE9*/
    src: url("../fonts/1238db2fc008ce88465a792fad1e5519.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/1238db2fc008ce88465a792fad1e5519.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/1238db2fc008ce88465a792fad1e5519.woff") format("woff"), /* chrome、firefox */
    url("../fonts/1238db2fc008ce88465a792fad1e5519.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/1238db2fc008ce88465a792fad1e5519.svg#Druk Wide Medium Regular") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}

/* Webfont: DrukWide-Bold */@font-face {
    font-family: 'DrukWideExp';
    src: url('../fonts/DrukWide-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/DrukWide-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/DrukWide-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/DrukWide-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/DrukWide-Bold.svg#DrukWide-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@mixin din-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'DINNextW1G', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin din-medium($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'DINNextW1GMedium', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin druk-medium($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'DrukWide', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin druk-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'DrukWideExp', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}
