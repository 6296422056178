@use "sass:math";

/**
VARIABLES
 */
$containerWidth: 1140;
$contextWidth : 1440;
$columnWidth: 62;
$gutterWith: 36;
$grid-columns: 12;
$gutter: math.div($gutterWith * 100, $containerWidth);
$column: math.div($columnWidth * 100, $containerWidth);

/**
QUERY
 */

/**
GRID
 */
.grid {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: $containerWidth * 1px;
    width: 100%;

    .row {
        position: relative;
        width: 100%;
        @include clearfix;
    }

    @media screen and (max-width: 1280px){
        width: calc(100% - 120px);
    }

    @media screen and (max-width: $mobile){
        width: calc(100% - 40px);
    }
}

[class*='column'] {
    float: left;
    min-height: 1px;
    width: $column * 1%;

    &.right {
        float: right;
    }
}

@for $i from 1 through $grid-columns
{
    $offset-gutter: $i - 1;

    .column-#{$i} {
        width: (($gutter * $offset-gutter) + ($column * $i)) * 1%;
    }
}

/**
UTILS
 */
.f-left {
    float: left;
}

.f-right {
    float: right;
}

.cf {
    pointer-events: none;
    @include clearfix;
}

.abs {
    position: absolute;
    float: none;
}

.img-responsive {

    &:before {
        content: "";
        display: block;
        padding-top: calc(var(--aspect-ratio)*100%);
    }

    .img-responsive__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;

            &.w-full {
                width: 100%;
                height: auto;
            }
        }
    }
}

/**
FUNCTIONS
 */
@function get-percent($width) {
    @return percentage($width / $containerWidth);
}

@function get-grid-percent($nb-gutters, $nb-columns) {
    @return (($gutter * $nb-gutters) + ($column * $nb-columns)) * 1%;
}

@mixin set-grid-prop($prop, $nb-gutters, $nb-columns) {
    #{$prop}: (($gutter * $nb-gutters) + ($column * $nb-columns)) * 1%;
}

@mixin set-vw($prop, $target) {
    $vw-context: ($contextWidth * 0.01) * 1px;
    $return: ($target / $vw-context) * 1vw;
    @media (min-width: $contextWidth * 1px) {
        #{$prop}: $target;
    }
    #{$prop}: $return;
}

/**
QUERY
 */

@media screen and (max-width: $mobile){

    .grid {
        //max-width: 500px;
        width: calc(100% - 40px);
    }

    .abs {
        position: relative !important;
        left: auto !important;
        top: auto !important;
        right: auto !important;
        margin: 0 auto;
    }

    [class*='column']:not(.no-responsive) {
        float: none;
        width: $column * 1%;
        margin-left: 0 !important;
    }

    [class*='column'].no-responsive {
        width: $column * 1%;
    }

    @for $i from 1 through $grid-columns
    {
        $offset-gutter: $i - 1;

        .column-#{$i}:not(.no-responsive) {
            width: 100% !important;
        }

        .column-#{$i}.no-responsive {
            width: (($gutter * $offset-gutter) + ($column * $i)) * 1%;
        }
    }

}
